import React, { useEffect } from "react";
import { Route, useNavigate, Routes } from "react-router-dom";
import { redirectToPreLoginUrl } from "./components/ValidateSession/index.js";
import { getDefaultPageLink } from "./components/NavigationSidebar/submission-options-sorted-byrole.js";

import {
    BusinessUnitSettings,
    Calendar,
    CategorySettings,
    Contracts,
    ContractDetail,
    CreateContract, 
    ContractTypes,
    ContractTypeDetail,
    CreateContractType, 
    CreateNewCategory,
    CategoryDetail,
    Documents,
    DocumentDetail,
    IssueDetail,
    ExportTables,
    Organizations,
    OrganizationDetail,
    CreateOrganization,
    NotificationSettings,
    Submissions,
    SubmissionDetail,
    CreateSubmission, 
    SubmissionTypes,
    SubmissionTypeDetail,
    CreateSubmissionType, 
    Users,
    UserDetail,
    CreateUser,
    Library,
    LibraryDetail,
    LibrarySettings,
    LibrarySearch,
    Archive,
    WebLog,
    AuditChangelogs,
    OtherSettings 
} from "./components/index.js";
import { PCDURedirect } from "./components/common/PCDURedirect/index.js";
import { useAutoFlag } from "./utils/useAutoFlag.js";
import { usePreloadedData } from "./components/Application/PreloadedData/index.js";


export const ContentRouting = ({ setSideNavigationRefetch }) => {
    const navigate = useNavigate();
    const { preloadedData, refetchPreloadedData } = usePreloadedData();
    const [categoryListRefetch, setCategoryListRefetch] = useAutoFlag(false);
    const defaultPage = getDefaultPageLink();
    const defaultPageDestination = defaultPage.props.to;

    useEffect(() => {
        const redirecturl = redirectToPreLoginUrl();
        if (redirecturl) {
            navigate(redirecturl);
        }
    });

    useEffect(() => {
        if (categoryListRefetch === true) {
            refetchPreloadedData();
        }
    }, [categoryListRefetch]);

    useEffect(() => { 
        if (location.pathname === "/" || location.pathname === "") {
            navigate(defaultPageDestination);
        }

        // if running locally and you are at /login, redirect to default submission page :: 
        if (location.hostname === "localhost" && location.pathname === "/login"  && location.pathname === "/login/sso") {
            navigate(defaultPageDestination);
        }
    }, [location.pathname]);

    const { categories } = preloadedData;

    const refetchSideNavCategories = () => {
        setSideNavigationRefetch(true);
    };

    return (
        <Routes>
            <Route
                path="submissions"
                key="submissions"
                caseSensitive={true}
                element={<Submissions />}
            >
                <Route
                    path="current"
                    key="submissions-current"
                    caseSensitive={true}
                    element={<Submissions />}
                />
                <Route
                    path="review"
                    key="submissions-to-review"
                    caseSensitive={true}
                    element={<Submissions />}
                />
                <Route
                    path="fix"
                    key="submissions-to-fix"
                    caseSensitive={true}
                    element={<Submissions />}
                />
                <Route
                    path="archived"
                    key="submissions-archived"
                    caseSensitive={true}
                    element={<Submissions />}
                />
                <Route
                    path="finalized"
                    key="submissions-finalized"
                    caseSensitive={true}
                    element={<Submissions />}
                />
                <Route
                    path="pending-failed"
                    key="submissions-pending-failed"
                    caseSensitive={true}
                    element={<Submissions />}
                />
            </Route>
            <Route
                path="submissions/:submissionId/issues/:id"
                key="issueDetail"
                caseSensitive={true}
                element={<IssueDetail />}
            />
            <Route
                path="documents/:documentId/issues/:id"
                key="issueDetail"
                caseSensitive={true}
                element={<IssueDetail />}
            />
            <Route 
                path="submissions/create"
                key="submission-create"
                caseSensitive={true}
                element={<CreateSubmission />}
            />
            <Route
                path="submissions/:id"
                key="submission-detail"
                caseSensitive={true}
                element={<SubmissionDetail />}
            />
            <Route
                path="submissions/:submissionId/documents/:id"
                key="document-detail"
                element={<DocumentDetail />}
            />
            <Route
                path="calendar"
                key="calendar"
                caseSensitive={true}
                element={<Calendar />}
            />
            <Route
                path="submission-types"
                key="submission-types"
                element={<SubmissionTypes categories={categories}/>}
            />
            <Route
                path="submission-types/category/:categoryId"
                key="submission-types-category"
                element={<SubmissionTypes categories={categories} />}
            />
            <Route
                path="submission-types/create"
                key="submission-type-detail"
                element={<CreateSubmissionType categories={categories} />}
            />

            <Route
                path="submission-types/:id"
                key="submission-type-detail"
                element={<SubmissionTypeDetail categories={categories} />}
            />
            <Route
                path="submission-types/category"
                key="category-settings"
                element={<CategorySettings refetchSideNavCategories={refetchSideNavCategories} setCategoryListRefetch={setCategoryListRefetch} />}
            />
            
            <Route
                path="submission-types/category/create"
                key="submission-type-detail"
                element={<CreateNewCategory refetchSideNavCategories={refetchSideNavCategories} setCategoryListRefetch={setCategoryListRefetch} />}
            />

            <Route
                path="submission-types/category/category-detail/:id"
                key="submission-type-detail"
                element={<CategoryDetail refetchSideNavCategories={refetchSideNavCategories} setCategoryListRefetch={setCategoryListRefetch} />}
            />

            <Route
                path="submission-types/business-unit"
                key="business-unit-settings"
                element={<BusinessUnitSettings />}
            />
            <Route
                path="submission-types/other-settings"
                key="other-settings"
                element={<OtherSettings />}
            />
            <Route
                path="/documents/archived"
                key="archived"
                element={<Archive />}
                caseSensitive={true}
            />

            <Route
                path="/contracts"
                key="contracts"
                caseSensitive={true}
                element={<Contracts />}
            />
            <Route
                path="/contracts/create"
                key="contract-detail"
                caseSensitive={true}
                element={<CreateContract />}
            />
            <Route
                path="/contracts/:id"
                key="contract-detail"
                caseSensitive={true}
                element={<ContractDetail />}
            />
            <Route
                path="/contract-types"
                key="contract-types"
                caseSensitive={true}
                element={<ContractTypes />}
            />
            <Route
                path="/contract-types/create"
                key="contract-type-detail"
                caseSensitive={true}
                element={<CreateContractType />}
            />
            <Route
                path="/contract-types/:id"
                key="contract-type-detail"
                caseSensitive={true}
                element={<ContractTypeDetail />}
            />
            <Route
                path="/documents"
                key="documents"
                caseSensitive={true}
                element={<Documents />}
            />
            <Route
                path="/documents/:id"
                key="document-detail"
                element={<DocumentDetail />}
            />
            <Route
                path="/documents/library"
                key="library"
                caseSensitive={true}
                element={<Documents />}
            />
            <Route
                path="/export-tables"
                key="export-tables"
                caseSensitive={true}
                element={<ExportTables />}
            />
            <Route
                path="/web-logs"
                key="web-logs"
                caseSensitive={true}
                element={<WebLog />}
            />
            <Route
                path="/users"
                key="users"
                caseSensitive={true}
                element={<Users />}
            />
            <Route
                path="/users/create"
                key="users"
                caseSensitive={true}
                element={<CreateUser />}
            />
            <Route
                path="/users/:id"
                key="userDetail"
                caseSensitive={true}
                element={<UserDetail />}
            />
            <Route
                path="/organizations"
                key="organizations"
                caseSensitive={true}
                element={<Organizations />}
            />
            <Route
                path="/organizations/create"
                key="organization-detail"
                caseSensitive={true}
                element={<CreateOrganization />}
            />
            <Route
                path="/organizations/:id"
                key="organization-detail"
                caseSensitive={true}
                element={<OrganizationDetail />}
            />
            <Route
                path="/settings/*"
                key="user-settings"
                caseSensitive={true}
                element={
                    <PCDURedirect 
                        to="/settings/library"
                        redirectIf={() => {
                            const validPathnameBranches = ["library", "category"];
                            const pathnameBranch = location.pathname.split("/").filter(Boolean)[1];
                            return !validPathnameBranches.includes(pathnameBranch);
                        }}
                    >
                        <NotificationSettings />
                    </PCDURedirect>
                }
            />
            <Route
                path="/library/settings/:contractTypeId"
                key="library-settings"
                caseSensitive={true}
                element={<LibrarySettings />}
            />
            <Route
                path="/library/documents/:libraryDocumentId"
                key="library-detail-page"
                caseSensitive={true}
                element={ <LibraryDetail /> } 
            />
            <Route
                path="/library/:contractTypeId/:baseFolderName/:baseFolderId"
                key="library"
                caseSensitive={true}
                element={<Library />}
            />
            <Route
                path="/library"
                key="library"
                caseSensitive={true}
                element={<LibrarySearch />}
            />
            <Route
                path="changelogs"
                key="changelogs"
                caseSensitive={true}
                element={<AuditChangelogs />}
            >
                <Route
                    path=":subject/:id"
                    key="changelogs-subject-detail"
                    caseSensitive={true}
                    element={<AuditChangelogs />}
                />

                <Route
                    path=":subject"
                    key="changelogs-subject"
                    caseSensitive={true}
                    element={<AuditChangelogs />}
                />
            </Route>
        </Routes>
    );
};