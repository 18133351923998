import { toMoment } from "../../utils/functions.js";
import React from "react";
import moment from "moment";

export const CALENDAR_BREADCRUMB = { label: "Calendar", path: "/calendar" };

export const showClickForMore = (data) => {
    let submissionRevs = 0, submissionSubs = 0, toDos = 0; 
    data.forEach(({ type }) => {
        if (type === "submission-rev") {
            submissionRevs++;
        } else if (type === "submission-sub") {
            submissionSubs++;
        } else if (type === "toDo") {
            toDos++;
        }
    });
    return (submissionRevs >= 3 || submissionSubs >= 3 || toDos >= 5) ? <p style={{display: "block", marginTop: "10px"}}> Click for more... </p> : null;
};

const titleMarkup = (base, range) => {
    if (!range) {
        return <span> { base } </span>;
    } else {
        return (
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between"}}>
                <div> { base } </div>
                <div> { range } </div>
            </div>
        );
    }
};

const dueRangeToText = (submissionDueRange) => {
    const [start, end] = submissionDueRange;
    const formattedStart = submissionDueRange?.[0] instanceof moment ? start.format("MMM Do") : "";
    const formattedEnd = submissionDueRange?.[1] instanceof moment ? end.format("MMM Do YYYY") : "";
    return formattedStart && formattedEnd ? `${formattedStart} - ${formattedEnd}` : "";
};


export const submissionTableTitle = ({ canCreateSubmission, userIsReviewerOrNotifier, canViewAllEntries, submissionDueRange = [] }) => {
    const range = dueRangeToText(submissionDueRange);
    let base;

    if (canViewAllEntries) {
        base = "Active Submissions";
    } else if (userIsReviewerOrNotifier) {
        base = "Submissions To Review";
    } else if (canCreateSubmission) {
        base = "Submissions To Re-Upload";
    } else {
        base = "Submissions";
    }

    return titleMarkup(base, range);
};

export const toDoTableTitle = ({ canCreateSubmission, submissionDueRange = [] }) => {
    const base = canCreateSubmission ? "To Submit (scheduled)" : "Upcoming Submissions (scheduled)";
    const range = dueRangeToText(submissionDueRange);
    return titleMarkup(base, range);
};

export const createDueRange = (current, scale = "month") => {
    const momentDate = toMoment(current);
    return [
        momentDate.clone().startOf(scale),
        momentDate.clone().endOf(scale)
    ];
};

export const restructureToDosData = (toDos = []) => {
    return toDos.map(td => {

        const documentTypes = td?.documentTypes ?? [];

        const timeline = {
            id: td.timelineId,
            currentDueDate: td.currentDueDate
        };

        const contract = {
            id: td.contractId, 
            name: td.contractName,
            startDate: td.contractStartDate,
            contractType: {
                id: td.contractTypeId,
                name: td.contractTypeName
            },
            promisor: {
                id: td.promisorId, 
                name: td.promisorName
            }
        };

        const obligation = {
            id: td.obligationId, 
            submissionType: {
                id: td.submissionTypeId, 
                specifier: td.submissionTypeSpecifier,
                name: td.submissionTypeName,
                daysToReview: td.submissionTypeDaysToReview,
                daysToResubmit: td.submissionTypeDaysToResubmit,
                useAttestation: td.submissionTypeUseAttestation,
                packagedSubmission: td.submissionTypePackagedSubmission, 
                documentTypes,
                categoryName: td.categoryName,
                reportTemplate:{
                    latestVersion: {
                        reportTemplateStatus: td?.reportTemplateLatestVersion?.reportTemplateStatus,
                        libraryDocumentId: td?.reportTemplateLatestVersion?.libraryDocumentId
                    },
                    activeVersion:{
                        reportTemplateStatus: td?.reportTemplateActiveVersion?.reportTemplateStatus
                    }
                },
                reportTemplateFlag: td?.reportTemplateFlag
            }
        };

        return {
            key: td.key, 
            timeline, 
            contract, 
            obligation 
        };
    });
};