import { gql } from "@apollo/client";

export const LibraryDocumentsQuery = gql`
    query LibraryDocumentsQuery (
        $libraryFolderId: ID
        $contractTypeId: ID!
        $documentsSortOn: String 
        $documentsSortBy: String 
        $documentsOffset: Int 
        $documentsLimit: Int 
        $search: Boolean 
        $activedocumentFilter: Boolean,
        $documentNameSearch: String 
        $contractTypeFilter: [ID] 
        $baseFolderFilter: [ID] 
        $includeS3Metadata: Boolean 
    ) {
        libraryDocuments ( 
            includeS3Metadata: $includeS3Metadata, 
            libraryFolderId: $libraryFolderId,
            contractTypeId: $contractTypeId, 
            sortOn: $documentsSortOn, 
            sortBy: $documentsSortBy, 
            offset: $documentsOffset,
            limit: $documentsLimit, 
            search: $search,
            nameSearch: $documentNameSearch, 
            activeFilter: $activedocumentFilter
            contractTypeFilter: $contractTypeFilter,
            baseFolderFilter: $baseFolderFilter
        ) {
            id 
            name
            fileSize
            dateModified 
            count 
            authorName 
            contractTypeName 
            parentFolderName 
            parentFolderId 
            baseFolderName 
            baseFolderId 
            contractTypeId 
            active
            S3UploadStatus 
            contractTypeCategoryName 
            failedS3Fetch 
            S3Metadata 
            reportTemplate {
                name 
                description 
                versions {
                    name
                    editable
                    deletable
                    uploadable
                    dateModified
                    dateCreated
                    active
                    startDate
                    endDate
                    versionNumber
                    reportTemplateStatus
                    deleted
                    author {
                        id 
                        name 
                    }
                }
            }
        }
    }
`;

export const LibraryQuery = gql`
    query LibraryQuery (
        $libraryFolderId: ID
        $contractTypeId: ID!
    ) {
        contractTypes (
            libraryAssociated: true
        ) {
            id
            name
            specifier
            description
            startDate
            endDate
            currentVersion
            count
        } 
        contractType (
            id: $contractTypeId
        ) {
            id 
            name 
            categories {
                id 
                name 
                deleted
            }
        }
        libraryFolderInfo(folderId: $libraryFolderId) {
            active
        }
        libraryExclusions(
            parentFolderId: $libraryFolderId,
            contractTypeId: $contractTypeId
        ) {
            id 
            name
        } 
        libraryFolderPathway (
            folderId: $libraryFolderId
        ) {
            name 
            id 
            contractType {
                id 
                name 
            } 
            baseFolder {
                id 
                name 
            }
        }
    }
`;

export const LibraryDocumentQuery = gql`
    query LibraryDocumentQuery (
        $id: ID!, 
        $includeS3Metadata: Boolean 
    ) {
        libraryDocument (
            id: $id, 
            includeS3Metadata: $includeS3Metadata 
        ) {
            id 
            name
            fileSize
            active
            S3UploadStatus 
            failedS3Fetch 
            S3Metadata 
            reportTemplate {
                name 
                description 
                versions {
                    name
                    editable
                    deletable
                    uploadable
                    dateModified
                    dateCreated
                    active
                    startDate
                    endDate
                    versionNumber
                    reportTemplateStatus
                    author {
                        id 
                        name 
                    }
                }
            }
        }
    }
`;

export const librarySettingsQuery = gql`
    query LibrarySettingsQuery (
        $id: ID! 
    ) {
        contractType (
            id: $id 
        ) {
            id 
            name  
            specifier
            description
            createdAt
            deleted
            startDate
            endDate
            currentVersion
            categories {
                id 
                name 
                deleted 
            }
        }
    }
`;

export const getFolderPathwayQuery = gql`
    query GetFolderPathwayQuery (
        $folderId: ID 
    ) {
        libraryFolderPathway (
            folderId: $folderId
        ) {
            name 
            id 
            contractType {
                id 
                name 
            } 
            baseFolder {
                id 
                name 
            }
        }
    }
`;

export const getContractTypesQuery = gql`
    query GetContractTypesQuery {
        contractTypes (
            libraryAssociated: true
        ) {
            id
            name
            specifier
            description
            startDate
            endDate
            currentVersion
            count
        } 
    }
`;

export const getLibraryFolderContentsQuery = gql`
    query GetLibraryFolderContentsQuery (
        $folderId: ID! 
        $contractTypeId: ID! 
        $nameSearch: String 
        $activeFilter: Boolean 
        $foldersFirst: Boolean 
        $sortOn: String 
        $sortBy: String 
        $offset: Int 
        $limit: Int 
    ) {
        libraryFolderContents (
            folderId: $folderId
            contractTypeId: $contractTypeId
            nameSearch: $nameSearch 
            activeFilter: $activeFilter 
            foldersFirst: $foldersFirst 
            sortOn: $sortOn
            sortBy: $sortBy
            offset: $offset
            limit: $limit
        ) {
            id
            fileSize
            name
            dateModified
            dateCreated
            authorId 
            authorName 
            active
            count
            parentFolderId
            S3UploadStatus
            failedS3Fetch
            S3Metadata
            isFolder
            reportTemplate {
                name 
                description 
                versions {
                    name
                    editable
                    deletable
                    uploadable
                    dateModified
                    dateCreated
                    active
                    startDate
                    endDate
                    versionNumber
                    reportTemplateStatus
                    author {
                        id 
                        name 
                    }
                }
            }
        }
    }
`;