import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CategoryAdditionalOptions } from "./CategoryAdditionalOptions.js";
import { Main } from "../../common/Main/index.js";
import { Col, Row, Form, Button, Modal } from "antd";
import { isUndefinedOrNull } from "../../../utils/functions.js";
import { UserContext } from "../../Application/UserContext.js";
import {
    CREATE_CATEGORY
} from "../../../constants/mutations.js";
import { useMutation } from "@apollo/client";
import { handleMutation } from "../../../utils/errorHandling.js";
import { PCDULink } from "../../common/PCDULink/index.js";
import { normalizeCategoryData } from "./_helpers.js";

const { confirm } = Modal;

export const CreateNewCategory = ({ refetchSideNavCategories, setCategoryListRefetch }) => {
    const navigate = useNavigate();
    const [modified, setModified] = useState({});
    const {
        userPermCreateSubTypeCategory
    } = useContext(UserContext);

    const requiredFields = [
        "name",
        "readonlyFinalizedFlag",
        "finalVersionRequestFlag",
        "bundledSubmissionFlag",
        "reviewersOption",
        "dqEnabledFlag",
        "bidpEnabledFlag",
        "reportTemplateFlag"
    ];

    const saveButtonsDisabled = requiredFields.some((key) => isUndefinedOrNull(modified[key]) || modified[key] === "");

    const [createCategory] = useMutation(CREATE_CATEGORY);

    const onSave = async () => {
        const categoryData = normalizeCategoryData(modified);
        const newCategoryResponse = await handleMutation(
            createCategory({
                variables: {
                    categoryInput: categoryData
                }
            }), {
                returnResponse: true,
                showSuccess: true,
                successMessage: `New Category Type: ${modified.name} created successfully!!`
            }
        );
        setCategoryListRefetch(true);
        refetchSideNavCategories();

        const { success, id } = newCategoryResponse.data.createCategory;

        if (success && id) {
            navigate(`/submission-types/category/category-detail/${id}`);
        }
    };

    const onCancel = () => {
        setModified({});
        navigate("/submission-types/category");
    };

    const showConfirmArchive = () => {
        confirm({
            title: "Please review your selections in the category type configuration before saving. Do you want to proceed with saving these configurations or return to make further adjustments ?",
            okText: "Confirm",
            async onOk() {
                onSave();
            }
        });
    };

    return (<Main
        title={"Create a New Category"}
        breadcrumbs={[
            "Settings",
            <PCDULink
                to="/submission-types/category"
                tooltip="Category"
            >
                Category
            </PCDULink>
        ]}>
        <div>
            <Form 
                className="high-contrast-radio-form"
                layout="vertical" 
                autoComplete="off" 
            >
                <CategoryAdditionalOptions
                    setModified={(e) => {
                        setModified({
                            ...modified,
                            [e.target.name]: e.target.value
                        });
                    }}
                    modified={modified}
                    readOnly={!userPermCreateSubTypeCategory}
                />
                {userPermCreateSubTypeCategory && (
                    <Row>
                        <Col>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={showConfirmArchive}
                                disabled={saveButtonsDisabled}
                            >
                                Save
                            </Button>

                            <Button
                                type="secondary"
                                onClick={onCancel}
                                className="spaceBetween-sm"
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    </Main>);
};