import { gql } from "@apollo/client";


export const libraryDetailQuery = gql`
    query LibraryDetailQuery ($id: ID!, $includeS3Metadata: Boolean) {
        libraryDocument(
            id: $id
            includeS3Metadata: $includeS3Metadata 
        ) {
            id
            name
            fileSize
            libraryFolderId
            contractTypeId
            baseFolderId
            dateModified
            dateCreated 
            active
            contractTypeName
            parentFolderName 
            parentFolderId
            parentFolderStatus
            baseFolderName
            S3UploadStatus 
            failedS3Fetch 
            S3Metadata
            deleted
            author {
                id 
                name 
            }
            reportTemplate {
                id
                name 
                description 
                activeVersion {
                    versionNumber
                    startDate
                    endDate
                }
                latestVersion {
                    versionNumber
                    startDate
                    endDate
                }
                versions {
                    libraryDocumentId
                    name
                    editable
                    deletable
                    uploadable
                    dateModified
                    dateCreated
                    active
                    startDate
                    endDate
                    versionNumber
                    S3UploadStatus
                    deleted
                    reportTemplateStatus
                }
            }
        }
        libraryFolderPathway (
            libraryDocumentId: $id 
        ) {
            name 
            id 
            
        } 
        libraryReportTemplate {
            name
            id
        }
    }
`;


export const submissionTypesQuery = gql`
    query SubmissionTypesQuery (
        $limit: Int
        $offset: Int
        $nameSearch: String
        $specifierSearch: String
        $categoriesFilter: [String]
        $contractTypesFilter: [ID] 
        $reportTemplateId: ID
        $sortOn: String
        $sortBy: String
    ) {
        availableSubmissionTypes: submissionTypesList(
            categoriesFilter: $categoriesFilter
            contractTypesFilter: $contractTypesFilter
            nullReportTemplate: true 
        ) {
            submissionTypes {
                id 
                name 
                specifier 
            }
        }
        assignedSubmissionTypes: submissionTypesList(
            limit: $limit
            offset: $offset
            nameSearch: $nameSearch
            specifierSearch: $specifierSearch
            reportTemplateId: $reportTemplateId
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            submissionTypes {
                id
                name
                specifier
                reportTemplateAssignedOn
            }
            count
        }
    }
`;