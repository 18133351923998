import { gql } from "@apollo/client";

export const createSubmissionQuery = gql`
    query CreateSubmissionQuery {
        contractUserList {
            contracts {
                id
                name
                contractType {
                    id
                    name
                    obligations {
                        id 
                        deleted 
                        submissionType {
                            id 
                            name
                            specifier
                            deleted 
                        }
                    }
                }
            }
        }
    }
`;

export const selectedSubmissionTypeQuery = gql`
    query SelectedSubmissionTypeQuery (
        $submissionTypeId: ID! 
        $organizationId: ID! 
    ) { 
        nextToDo (
            organizationId: $organizationId,
            submissionTypeId: $submissionTypeId
        ) {
            timeline {
                id
                currentDueDate
            }
        }
        submissionType (id: $submissionTypeId) {
            id
            name
            reportTemplate {
                id
                name 
                description 
                activeVersion {
                    versionNumber
                    startDate
                    endDate
                    reportTemplateStatus
                }
                latestVersion {
                    libraryDocumentId
                    name
                    editable
                    deletable
                    uploadable
                    dateModified
                    dateCreated
                    active
                    startDate
                    endDate
                    versionNumber
                    S3UploadStatus
                    reportTemplateStatus
                }
                versions {
                    libraryDocumentId
                    name
                    editable
                    deletable
                    uploadable
                    dateModified
                    dateCreated
                    active
                    startDate
                    endDate
                    versionNumber
                    S3UploadStatus
                    reportTemplateStatus
                }
            }
            specifier
            description
            dqFlag
            daysToReview
            daysToReSubmit
            frequency
            useAttestation
            packagedSubmission
            category {
                id 
                name 
                reportTemplateFlag
            }
            deleted  
            submissionTypeNewestVersion {
                id
                specifier
                startDate
                adhocDueDate
                frequency
                dueOffset {
                    hours
                    days
                    months
                }
                periodOffset {
                    hours
                    days
                    months
                }
            }
            criteria {
                id
                specifier
                citation
                description
            }
            documentTypes {
                id
                name 
                specifier 
            }
            reportTemplateId
        }
    }
`;


export const submissionDetailQuery = gql`
    query SubmissionDetailQuery (
        $id: ID!,
    ) {
        submission(id: $id) {
            id
            specifier
            createdAt
            finalizedAt
            status
            finalized 
            archived
            requestForArchive 
            submitterDueDate
            initialSubmitterDueDate
            adhocDueDate
            uploadStatus 
            archiveReason {
                id 
                text 
            }
            timeline {
                startDate
                endDate
                currentDueDate
            }
            contract {
                id
                contractType {
                    id
                    name
                }
            }
            submitter {
                __typename
                ... on Organization {
                    id
                    name
                }
            }
            issues {
                id
                specifier
                raisedInReviewId
                status 
                reason 
                readyForReview
                inactiveFeedbackDocumentsCount
                activeFeedbackDocument{
                    id 
                    name 
                    fileSize 
                    S3UploadStatus
                }
                reactions {
                    reviewerId
                    disposition
                    comment 
                    createdAt 
                    issueId 
                    reviewer {
                        id 
                        name 
                        organizations {
                            id 
                            name 
                        }
                    }
                }
                criteria {
                    specifier 
                }
                raisedInReview {
                    submissionVersion { 
                        id
                        specifier 
                        createdAt 
                    }
                    reviewer {
                        id 
                        name 
                    }
                }
                relatedDocuments {
                    id 
                    specifier 
                    nickname 
                    issueResolved
                    documentNewestVersion {
                        id
                        name
                    }
                }
                comments {
                    id
                    content
                    parentId
                    authorId
                    isCorrectionNote 
                    previousDueDate
                    updatedDueDate
                    author {
                        id 
                        name 
                        organizations {
                            id 
                            name 
                        }
                    }
                    createdAt
                    modifiedAt
                    readyForReview
                    submissionVersionId
                }
                feedbackDocuments {
                    id
                    name
                    S3UploadStatus
                    active
                    issueId
                }
            }
            submissionNewestVersion {
                id
                specifier
                requestForArchive
                createdAt 
                archived 
                archiveReason {
                    id 
                    text 
                }
                reviews {
                    id
                    completed 
                    modifiedAt 
                    reviewer {
                        id
                        name
                        email
                    }
                    roleSpecifier
                    deleted
                }
            }
            submissionVersions {
                id 
                specifier 
                requestForArchive
                createdAt 
                archived 
                archiveReason {
                    id 
                    text 
                }
                reviews {
                    id
                    reviewer {
                        id
                        name
                    }
                    completed
                }
            }
            submissionDocuments {
                id
                specifier
                nickname
                status
                currentStatusDueDate
                S3UploadStatus
                documentType {
                    id 
                    specifier
                    name
                }
                documentNewestVersion {
                    id
                    name
                    specifier
                    dqFlag
                    dqStatus
                    reportTemplateVersion
                }
                documentVersions {
                    id
                    name
                    specifier
                    createdAt
                    reportTemplateVersion
                }
                issues {
                    id
                    specifier
                    raisedInReviewId
                    status
                    reason 
                    reactions {
                        reviewerId
                        disposition
                        comment 
                        createdAt 
                        issueId 
                        reviewer {
                            id 
                            name 
                            organizations {
                                id 
                                name 
                            }
                        }
                    }
                    criteria {
                        specifier 
                    }
                    raisedInReview {
                        submissionVersion { 
                            id
                            specifier 
                            createdAt 
                        }
                        reviewer {
                            id 
                            name 
                        }
                    }
                }
            }
            submissionTypeVersion{
                id
                specifier
                startDate
                adhocDueDate
                frequency
                dueOffset {
                    hours
                    days
                    months
                }
                periodOffset {
                    hours
                    days
                    months
                }
            }
            obligation {
                id
                submissionType {
                    id
                    name
                    specifier
                    reportTemplate {
                        id
                        name 
                        description 
                        activeVersion {
                            versionNumber
                            startDate
                            endDate
                            reportTemplateStatus
                        }
                        latestVersion {
                            libraryDocumentId
                            name
                            editable
                            deletable
                            uploadable
                            dateModified
                            dateCreated
                            active
                            startDate
                            endDate
                            versionNumber
                            S3UploadStatus
                            reportTemplateStatus
                        }
                        versions {
                            libraryDocumentId
                            name
                            editable
                            deletable
                            uploadable
                            dateModified
                            dateCreated
                            active
                            startDate
                            endDate
                            versionNumber
                            S3UploadStatus
                            reportTemplateStatus
                        }
                    }
                    frequency
                    dqFlag
                    description
                    category {
                        id 
                        name 
                        finalVersionRequestFlag
                        reportTemplateFlag
                    }
                    daysToReview
                    daysToReSubmit
                    useAttestation
                    packagedSubmission
                    reviewerAssignments {
                        id
                        role {
                            __typename
                            ... on Role {
                                id
                                specifier
                            }
                        }
                        assigneeId
                        assignee {
                            __typename
                            ... on User {
                                id
                                name
                            }
                        }
                    }
                    documentTypes {
                        id
                        name 
                        specifier 
                    }
                    criteria {
                        id
                        description
                        specifier
                        citation 
                    }
                    allCriteria {
                        id
                        description
                        specifier
                        citation 
                        deleted 
                        createdAt 
                        versions {
                            id 
                            description 
                            citation 
                            deleted 
                            createdAt 
                        }
                    }
                    obligations {
                        id
                        criteria {
                            description
                        }
                    }
                }
            }
            comments {
                id
                content 
                readyForReview 
                createdAt 
                modifiedAt 
                previousDueDate
                updatedDueDate
                author {
                    id
                    email
                    name
                    organizations {
                        name
                    }
                }
            }
        }
    }
`;