import { gql } from "@apollo/client";

export const submissionTypeDetailQuery = gql`
    query SubmissionTypeDetailQuery (
        $id: ID!
        $rolesSpecifier: String
        $notifierRole: String
    ) {
        submissionType(id: $id) {
            hasSubmissions
            reportTemplate {
                id
                name 
                description 
                activeVersion {
                    versionNumber
                    startDate
                    endDate
                    reportTemplateStatus
                    libraryDocumentId
                }
                latestVersion {
                    versionNumber
                    startDate
                    endDate
                    reportTemplateStatus
                    libraryDocumentId
                }
                versions {
                    libraryDocumentId
                    name
                    editable
                    deletable
                    uploadable
                    dateModified
                    dateCreated
                    active
                    startDate
                    endDate
                    versionNumber
                    S3UploadStatus
                    reportTemplateStatus
                }
            }
            category {
                reportTemplateFlag
            }
            editable 
            id
            name
            specifier
            description
            categoryId
            daysToReview
            daysToReviewType
            daysToReSubmit
            daysToReSubmitType
            bidpFlag
            dqFlag
            startDate
            adhocDueDate
            frequency
            submissionTypeVersions {
                id
                specifier
                startDate
                adhocDueDate
                frequency
                dueOffset {
                    hours
                    days
                    months
                }
                periodOffset {
                    hours
                    days
                    months
                }
            }
            submissionTypeNewestVersion {
                id
                specifier
                startDate
                adhocDueDate
                frequency
                dueOffset {
                    hours
                    days
                    months
               }
               periodOffset {
                    hours
                    days
                    months
              }
            }
            submissionTypeCurrentVersion {
                id
                specifier
                startDate
                adhocDueDate
                frequency
                dueOffset {
                    hours
                    days
                    months
               }
               periodOffset {
                    hours
                    days
                    months
              }
            }
            deleted
            businessUnitId
            businessUnitName
            useAttestation
            packagedSubmission
            categoryName
            allCriteria {
                id
                specifier
                citation
                description 
                createdAt 
                deleted 
                versions { 
                    id 
                    citation 
                    description 
                    deleted 
                    createdAt 
                }
            }
            contractType {
                id
                name
                contractTypeNewestVersion {
                    id
                    startDate
                    endDate
                    contractActive
                }
            }
            documentTypes {
                id
                name 
                specifier
            }
            reviewerAssignments {
                id
                role {
                    id
                    specifier
                }
                assignee {
                    __typename
                    ... on User {
                        id
                        name
                        email
                    }
                }
            }
            validNewAssignees {
                id
                name
                email 
            }
            notifierAssignments {
                id
                role {
                    id
                    specifier
                }
                assignee {
                    __typename
                    ... on User {
                        id
                        name
                        email
                    }
                }
            }
        }
        submissionTypes: submissionTypeFilterOptions
        contractTypes {
            id
            name
            specifier
            startDate
            endDate
            contractTypeNewestVersion {
                id
            }
        }
        roles (
            specifierSearch: $rolesSpecifier
        ) {
            id
            specifier
        }
        role (
            specifier: $notifierRole
        ) {
            id
            specifier
        }
    }
`;


export const createSubmissionTypeQuery = gql`
    query CreateSubmissionTypeQuery (
        $activeFilter: Boolean
    ) {
        submissionTypes: submissionTypeFilterOptions
        contractTypes (
            activeFilter: $activeFilter
        ) {
            id
            name
            specifier
            startDate
            endDate
            contractTypeNewestVersion {
                id
            }
        }
    }
`;

export const submitterNextDueDateQuery = gql`
    query SubmitterNextDueDateQuery (
        $id: ID!
        $toDoOrganization: ID
    ){
        nextToDo (
            submissionTypeId: $id,
            organizationId: $toDoOrganization
        ) {
            contract {
                id
            }
            obligation {
                id
            }
            timeline {
                id
                currentDueDate
            }
        }
    }
`;

export const submissionsOfSubmissionTypeQuery = gql`
    query SubmissionsTableSubmissionsQuery (
        $limit: Int
        $offset: Int
        $submissionTypesFilter: [ID]
        $specifierSearch: String
        $organizationsFilter: [ID]
        $statusFilter: [String]
        $currentOrAdhocDueDateFilter: [String]
        $sortOn: String
        $sortBy: String
        $yearFilter: Int
    ) {
        submissionsList (
            limit: $limit
            offset: $offset
            submissionTypesFilter: $submissionTypesFilter
            specifierSearch: $specifierSearch
            organizationsFilter: $organizationsFilter
            statusFilter: $statusFilter
            currentOrAdhocDueDateFilter: $currentOrAdhocDueDateFilter
            sortOn: $sortOn
            sortBy: $sortBy
            yearFilter: $yearFilter
        ) {
            submissions {
                id
                specifier
                status
                uploadStatus
                adhocDueDate 
                initialSubmitterDueDate 
                timeline {
                    currentDueDate
                    initialDueDate
                }
                submissionDocuments {
                    id
                }
                submitter {
                    __typename
                    ... on Organization {
                        id
                        name
                    }
                }
                submissionNewestVersion{
                    id
                }
                submitterDueDate
                submissionTypeVersion {
                    frequency
                }
                comments {
                    content
                    previousDueDate
                    updatedDueDate
                    createdAt
                    modifiedAt
                    author {
                        name
                        organizations {
                            id 
                            name 
                        }
                    }
                }
            }
            count
        }
    }
`;