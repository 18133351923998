import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Modal, Row, Col } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { LabeledDetail } from "../common/LabeledDetail/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { submissionTypeDetailQuery } from "./query.js";
import { SubSubmissionTypeTable } from "./SubSubmissionTypeTable.js";
import { SubmissionTypeCriteriaTable } from "./CriteriaTable.js";
import { ReviewersTable } from "./ReviewersTable.js";
import { DueDateSubmission } from "./DueDateSubmission.js";
import { isUndefinedOrNull } from "../../utils/functions.js";
import { MODIFY_SUBMISSION_TYPE } from "../../constants/mutations.js";
import { getHistoryBreadcrumbs } from "../../utils/getHistoryBreadcrumbs.js";
import { getGenuineModifications, handleGraphQLError, handleMutation, invalidModificationsMessage } from "../../utils/errorHandling.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { SubmissionsTable } from "./SubmissionsTable.js";
import { TimelinesTable } from "./TimelinesTable.js";
import { ScheduleDetails } from "./ScheduleDetails.js";
import {
    buildNewSubmissionType,
    SAVE_BUTTON_FIELDS,
    unEditableMessage,
    unArchivableMessage,
    getNewBreadcrumbs,
    SUBMISSION_TYPES_NEW_VERSION_FIELDS,
    validateSubmissionTypeSpecifier,
    getSubmissionTypeSelectFields
} from "./_helpers.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";
import { SubmissionTypeDetailFields } from "./SubmissionTypeDetailFields.js";
import { DOCUMENT_UPLOAD_TYPES } from "../../constants/index.js";
import { DownloadButton } from "../common/DownloadButton/index.js";


export const SubmissionTypeDetail = ({ categories }) => {
    const params = useParams();
    const lastHistoryBreadcrumb = getHistoryBreadcrumbs(-1);
    const navigate = useNavigate();
    const { preloadedData } = usePreloadedData();

    const {
        businessUnits,
        constants
    } = preloadedData;

    const [modified, setModified] = useState({});
    const [modifySubmissionType] = useMutation(MODIFY_SUBMISSION_TYPE);
    const [addingDescription, setAddingDescription] = useState(false);
    const [displayedVersion, setDisplayedVersion] = useState(null);
    const [versionFieldsConfirmation, setVersionFieldsConfirmation] = useState(false);
    const [refetchTimelines, setRefetchTimelines] = useState(false);
    const [refetchSubmissions, setRefetchSubmissions] = useState(false);
    const [toDisplay, setToDisplay] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [fields, setFields] = useState([]);

    const {
        userPermEditSubmissionType,
        userPermViewContractTypes,
        userIsSubmitter,
        userIsContractAdmin,
        userIsSystemAdmin,
        userPermViewUserAuditLog
    } = useContext(UserContext);

    const { loading, error, data, refetch } = useQuery(submissionTypeDetailQuery,
        {
            variables: {
                id: params.id,
                rolesSpecifier: "reviewer",
                notifierRole: "SECONDARY_NOTIFIER"
            },
            fetchPolicy: "no-cache"
        }
    );

    const submissionType = data?.submissionType ? data.submissionType : {};
    const reportTemplate = (data?.submissionType?.id && submissionType?.reportTemplate) ? submissionType?.reportTemplate : null;
    const versions = (data?.submissionType?.id && submissionType?.reportTemplate?.versions) ? submissionType?.reportTemplate?.versions : [];
    const activeVersionNumber = (data?.submissionType?.id && reportTemplate?.activeVersion) ? reportTemplate?.activeVersion?.versionNumber : null;
    let document = data?.submissionType?.id ? versions?.find((item) => item.versionNumber === activeVersionNumber) : null;
    document = document ? {...document, id:document?.libraryDocumentId || ""} : null;

    const latestVersion = submissionType?.submissionTypeNewestVersion?.id;
    const currentVersion = submissionType?.submissionTypeCurrentVersion?.id;

    useEffect(() => {
        if (currentVersion) {
            setDisplayedVersion(currentVersion);
        }
    }, [currentVersion]);

    useEffect(() => {
        if (submissionType?.categoryId) {
            const category = categories.find(({ id }) => id === submissionType.categoryId);
            setFields(getSubmissionTypeSelectFields({ category, businessUnits, constants, submissionType }));
        }
    }, [submissionType.categoryId]);

    if (loading) {
        return <LoadingContent />;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    if (isUndefinedOrNull(data.submissionType)) {
        return <NoContent />;
    }

    const newBreadcrumbsForLinks = getNewBreadcrumbs(submissionType, lastHistoryBreadcrumb);

    const {
        submissionTypes,
        contractTypes,
        roles,
        role: notifierRole
    } = data;

    // if an older version is currently selected, the details fields should not be editable 
    // in addition, if a Submission Type is archived or belongs to an archived S.T. category, it should not be editable :: 
    const isEditable = submissionType.editable && !submissionType.deleted && (latestVersion && displayedVersion === latestVersion);
    const isArchivable = submissionType.editable;

    const editMessageToDisplay = !isArchivable
        ? unArchivableMessage
        : submissionType.deleted ? unEditableMessage : "";

    const disableDetailsEditing = isEditable !== true;

    const hasSecondaryNotifiers = categories?.find(({ id }) => id === submissionType?.categoryId)?.secondaryNotifierFlag === true;

    const displayedVersionData = submissionType?.submissionTypeVersions?.find?.(v => v.id === displayedVersion);
    const isNullingAdhocDueDate = displayedVersionData?.adhocDueDate && modified?.adhocDueDate === null;
    const saveButtonsVisible = userPermEditSubmissionType && (isNullingAdhocDueDate || Object.keys(modified).some(key => !isUndefinedOrNull(modified[key]) && SAVE_BUTTON_FIELDS[key]));

    const versionFieldsConfirmationModal = () => {
        let versionFieldsModified = [];
        Object.keys(modified).forEach((key) => {
            if (!isUndefinedOrNull(modified[key]) &&
                SUBMISSION_TYPES_NEW_VERSION_FIELDS[key] &&
                !versionFieldsModified.includes(SUBMISSION_TYPES_NEW_VERSION_FIELDS[key]) &&
                !((toDisplay.length <= 0) && (key == "periodMonths" || key == "periodDays"))
            ) {
                versionFieldsModified.push(SUBMISSION_TYPES_NEW_VERSION_FIELDS[key]);
            }
        });

        const zeroSubmissionMessage = "Editing the below fields would update the Scheduled Due dates. Would you still like to continue?";
        const withSubmissionMessage = "The Submission Type already has Submissions. Editing the below fields would update the Scheduled Due dates. Are you sure you want to Proceed?";
        const displayMessage = submissionType?.hasSubmissions ? withSubmissionMessage : zeroSubmissionMessage;
        return (
            <Modal
                open={versionFieldsConfirmation}
                okText="Save"
                onOk={() => {
                    updateSubmissionTypes();
                    setVersionFieldsConfirmation(false);
                }}
                onCancel={() => {
                    setVersionFieldsConfirmation(false);
                }}
            >
                <p></p>
                <p>{displayMessage}</p>
                <ul>
                    {versionFieldsModified.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </Modal>
        );
    };

    const updateSubmissionTypes = async () => {
        const newSubmissionType = buildNewSubmissionType(modified, isNullingAdhocDueDate);
        const nameAndSpecifierModifications = getGenuineModifications(newSubmissionType, submissionType, {
            limitTo: ["name", "specifier"],
            noMessage: true,
            trim: true
        }) ?? {
            name: undefined,
            specifier: undefined
        };
        const latestContractTypeVersionId = contractTypes
            .find(({ id }) => id === submissionType.contractType.id)
            ?.contractTypeNewestVersion?.id;

        const finalInput = { ...newSubmissionType, ...nameAndSpecifierModifications };
        const isInvalid = Object.keys(finalInput).every(property => {
            const value = finalInput[property];
            if (property === "adhocDueDate") {
                return !isNullingAdhocDueDate && isUndefinedOrNull(value);
            } else {
                return isUndefinedOrNull(value);
            }
        });

        if (isInvalid) {
            message.error(invalidModificationsMessage);
        } else {
            const modSTMutation = modifySubmissionType({
                variables: {
                    id: submissionType.id,
                    newSubmissionType: finalInput,
                    contractTypeVersionId: latestContractTypeVersionId
                }
            });

            await handleMutation(modSTMutation, {
                showSuccess: true
            });

            await refetch();
            setModified({});
            setRefetchTimelines(true);
            setAddingDescription(false);
        }
    };

    const details = (
        <div id="details">
            <Row>
                <Col span={12}>
                    {userPermViewUserAuditLog && submissionType.id &&
                        <div style={{ margin: "10px 0px 15px 0px", fontSize: "16px" }}>
                            <PCDULink
                                to={`/changelogs/submission-types/${submissionType.id}`}
                                newBreadcrumbs={newBreadcrumbsForLinks}
                            >
                                Changelogs
                            </PCDULink>
                        </div>
                    }
                </Col>
                {reportTemplate && document && <Col span={12} style={{
                    display: "flex",
                    justifyContent: "end"
                }}>
                    <div>
                        <DownloadButton
                            type={DOCUMENT_UPLOAD_TYPES.LIBRARY}
                            text="Download Report Template"
                            document={document}
                        />
                    </div>
                </Col>}

            </Row>
            {userPermEditSubmissionType && editMessageToDisplay &&
                <div style={{ marginBottom: "25px" }}>
                    <span style={{ color: "red", display: "inline-block" }}> Note </span>
                    <p>{editMessageToDisplay} </p>
                </div>
            }

            <LabeledDetail title="Contract Type">
                {userPermViewContractTypes
                    ? <PCDULink
                        to={`/contract-types/${submissionType.contractType.id}`}
                        newBreadcrumbs={newBreadcrumbsForLinks}
                    >
                        {submissionType.contractType.name}
                    </PCDULink>
                    : submissionType.contractType.name
                }
            </LabeledDetail>

            <SubmissionTypeDetailFields
                isEditable={userPermEditSubmissionType}
                submissionType={submissionType}
                addingDescription={addingDescription}
                setAddingDescription={setAddingDescription}
                modified={modified}
                setModified={setModified}
                constants={constants}
                categories={categories}
                businessUnits={businessUnits}
                otherFields={fields}
                setFields={setFields}
            />

            {displayedVersion
                ? <ScheduleDetails
                    creating={false}
                    contractTypes={contractTypes}
                    submissionType={submissionType}
                    frequencyOptions={constants.FREQUENCY_OPTIONS}
                    displayedVersion={displayedVersion}
                    modified={modified}
                    setModified={setModified}
                    canEditSchedule={(userIsContractAdmin || userIsSystemAdmin) && !disableDetailsEditing}
                    setToDisplay={setToDisplay}
                />
                : null
            }
            {(
                userIsSubmitter
                    ? null
                    : <div
                        className="detail-panel spaceAbove-sm"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "150px"
                        }}
                    >
                        <SelectableDetail
                            title="Version"
                            containerStyle={{ width: "100%" }}
                            passedKey="submission-type-version-select"
                            options={submissionType?.submissionTypeVersions.map(({ id, specifier }) => {
                                return {
                                    id,
                                    text: `${parseInt(specifier) + 1}`,
                                    value: id
                                };
                            })}
                            value={displayedVersion}
                            onValueUpdated={(value) => {
                                // need to reset any changes the user tried to make :: 
                                setModified({});
                                setAddingDescription(false);
                                setDisplayedVersion(value);
                            }}
                            refetch={() => refetch()}
                        />
                    </div>
            )}
            <br />
            {saveButtonsVisible
                ? <Button
                    type="primary"
                    disabled={updating}
                    onClick={async () => {
                        const newSubmissionType = buildNewSubmissionType(modified, isNullingAdhocDueDate);
                        const newSpecifier = newSubmissionType?.specifier;
                        if (newSpecifier && !validateSubmissionTypeSpecifier(newSpecifier, submissionTypes, constants)) {
                            return;
                        } else {
                            setUpdating(true);
                            // Get Version change fields
                            const isVersionFieldsEdited = Object.keys(modified).some(key => {
                                return !isUndefinedOrNull(modified[key]) && SUBMISSION_TYPES_NEW_VERSION_FIELDS[key];
                            });
                            if (isVersionFieldsEdited) {
                                setVersionFieldsConfirmation(true);
                                setUpdating(false);
                            } else {
                                updateSubmissionTypes().finally(() => {
                                    setUpdating(false);
                                });
                            }
                        }
                    }}
                >
                    Save Changes
                </Button>
                : null
            }
            {saveButtonsVisible
                ? <Button
                    style={{
                        marginLeft: "10px"
                    }}
                    type="danger"
                    disabled={updating}
                    onClick={() => {
                        setModified({});
                        setAddingDescription(false);
                        setToDisplay([]);
                    }}
                >
                    Discard Changes
                </Button>
                : null
            }
            {versionFieldsConfirmationModal()}
        </div>
    );

    return (
        <Main
            showArchive={userPermEditSubmissionType && isArchivable}
            archived={submissionType.deleted}
            confirmAction={async () => {
                const success = await handleMutation(
                    modifySubmissionType({
                        variables: {
                            id: submissionType.id,
                            newSubmissionType: { deleted: !submissionType.deleted }
                        }
                    })
                );

                if (success) {
                    navigate("/submission-types/");
                }
            }}
            confirmTitle={`Are you sure you want to ${submissionType.deleted ? "de-" : ""}archive ${submissionType.name}?`}
            title={`Submission Type - ${submissionType.specifier} ${submissionType.deleted ? "(Archived)" : ""}`}
            details={details}
            breadcrumbs={[
                lastHistoryBreadcrumb?.path === "/submission-types"
                    ? null
                    : <PCDULink
                        to="/submission-types"
                        tooltip="All Submission Types"
                    >
                        Submission Types
                    </PCDULink>,
                submissionType.specifier
            ]}
        >
            <SubmissionTypeCriteriaTable
                submissionType={submissionType}
                refetchSubmissions={refetch}
            />
            <SubSubmissionTypeTable
                submissionType={submissionType}
                refetchSubmissions={refetch}
            />
            <ReviewersTable
                submissionType={submissionType}
                roles={roles}
                secondaryNotifierFlag={hasSecondaryNotifiers}
                notifierRole={notifierRole}
                refetchSubmissions={refetch}
                tableType={"REVIEWERS"}
            />
            {hasSecondaryNotifiers && !userIsSubmitter
                ? <ReviewersTable
                    submissionType={submissionType}
                    roles={roles}
                    secondaryNotifierFlag={hasSecondaryNotifiers}
                    notifierRole={notifierRole}
                    refetchSubmissions={refetch}
                    tableType={"NOTIFIER"}
                />
                : null
            }
            <DueDateSubmission
                submissionType={submissionType}
                data={data}
            />
            <SubmissionsTable
                submissionType={submissionType}
                refetchSubmissions={refetchSubmissions}
                setRefetchSubmissions={setRefetchSubmissions}
            />
            <TimelinesTable
                submissionTypeId={params.id}
                refetchTimelines={refetchTimelines}
                setRefetchTimelines={setRefetchTimelines}
                setRefetchSubmissions={setRefetchSubmissions}
            />
        </Main>
    );
};